import React, { useState, useEffect } from 'react'

import HcpdFilterRow from './HcpdFilterRow'
import AdditionalFiltersBlock from './AdditionalFiltersBlock'
import HcpdProviderList from './HcpdProviderList'
import { DEFAULT_RADIUS } from './hcpdConstants'
import {
  getStorageString,
  setStorageString,
  removeStorageString,
  StorageKey,
  StorageType,
} from '../../util/storage'
import { isEmpty, paginationChange } from '../../util/helper'

const HcpdLandingFilterableList = ({ noResultsMessage }) => {
  const [radiusValue, setRadiusValue] = useState(DEFAULT_RADIUS)
  const [latLon, setLatLon] = useState({})
  const [locationInput, setLocationInput] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [additionalFilters, setAdditionalFilters] = useState([])
  const hasPreviousLocationFilterInput = getStorageString({
    key: StorageKey.HCPD_LOCATION_FILTER_INPUT,
    storageType: StorageType.SESSION,
  })
  const hasPreviousAdditionalFilterInput = getStorageString({
    key: StorageKey.HCPD_ADDITIONAL_FILTER_INPUT,
    storageType: StorageType.SESSION,
  })
  const hasPreviousRadiusInput = getStorageString({
    key: StorageKey.HCPD_RADIUS_FILTER_INPUT,
    storageType: StorageType.SESSION,
  })
  const hasPreviousLatLon = getStorageString({
    key: StorageKey.HCPD_LAT_LON,
    storageType: StorageType.SESSION,
  })

  useEffect(() => {
    if (hasPreviousLocationFilterInput) {
      setLocationInput(hasPreviousLocationFilterInput)
    }
    if (hasPreviousLatLon) {
      setLatLon(JSON.parse(hasPreviousLatLon))
    }
    if (hasPreviousRadiusInput) {
      setRadiusValue(JSON.parse(hasPreviousRadiusInput))
    }
    if (hasPreviousAdditionalFilterInput) {
      setAdditionalFilters(JSON.parse(hasPreviousAdditionalFilterInput))
    }
  }, [getStorageString])

  useEffect(() => {
    if (!locationInput || locationInput === '') {
      if (hasPreviousLocationFilterInput) {
        removeStorageString({
          key: StorageKey.HCPD_LOCATION_FILTER_INPUT,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.HCPD_LOCATION_FILTER_INPUT,
      value: locationInput,
      storageType: StorageType.SESSION,
    })
  }, [locationInput])

  useEffect(() => {
    if (isEmpty(latLon)) {
      if (hasPreviousLatLon) {
        removeStorageString({
          key: StorageKey.HCPD_LAT_LON,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.HCPD_LAT_LON,
      value: JSON.stringify(latLon),
      storageType: StorageType.SESSION,
    })
  }, [latLon])

  useEffect(() => {
    // console.log(radiusValue)
    if (radiusValue.value === DEFAULT_RADIUS.value) {
      if (hasPreviousRadiusInput) {
        removeStorageString({
          key: StorageKey.HCPD_RADIUS_FILTER_INPUT,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.HCPD_RADIUS_FILTER_INPUT,
      value: JSON.stringify(radiusValue),
      storageType: StorageType.SESSION,
    })
  }, [radiusValue])

  useEffect(() => {
    if (additionalFilters.length <= 0) {
      if (hasPreviousAdditionalFilterInput) {
        removeStorageString({
          key: StorageKey.HCPD_ADDITIONAL_FILTER_INPUT,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.HCPD_ADDITIONAL_FILTER_INPUT,
      value: JSON.stringify(additionalFilters),
      storageType: StorageType.SESSION,
    })
  }, [additionalFilters])

  return (
    <>
      <HcpdFilterRow
        radiusValue={radiusValue}
        latLon={latLon}
        locationInput={locationInput}
        searchInput={searchInput}
        setRadiusValue={setRadiusValue}
        setLatLon={setLatLon}
        setSearchInput={setSearchInput}
        setLocationInput={setLocationInput}
        paginationChange={paginationChange}
      />
      <AdditionalFiltersBlock
        additionalFilters={additionalFilters}
        setAdditionalFilters={setAdditionalFilters}
        setLocationInput={setLocationInput}
        setLatLon={setLatLon}
        setSearchInput={setSearchInput}
      />
      <HcpdProviderList
        latLon={latLon}
        radius={radiusValue.value}
        searchFilter={searchInput}
        additionalFilters={additionalFilters}
        noResultsMessage={noResultsMessage}
        paginationChangeAction={paginationChange}
      />
    </>
  )
}

export default HcpdLandingFilterableList
