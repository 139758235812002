import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import FilterRow from '../FilterRow'
import { DROPDOWN_STYLES } from '../../util/constants'
import { DEFAULT_RADIUS, RADIUS_OPTIONS } from './hcpdConstants'
import * as Styled from './styles/HcpdLandingFilterableList.styles'
import { useLatLonFromAddress } from '../../util/geolocation'
import { SearchField } from '../styles/FilterRow.styles'

// const GOOGLE_GEO_URL_PREFIX = 'https://maps.googleapis.com/maps/api/geocode/json'
// address=${query}&key=${process.env.GATSBY_GOOGLE_MAPS_KEY}`

const HcpdFilterRow = ({
  radiusValue,
  setRadiusValue,
  setLatLon,
  setSearchInput,
  searchInput,
  locationInput,
  setLocationInput,
}) => {
  const [address, setAddress] = useState(null)
  const addressLatLon = useLatLonFromAddress(address)

  useEffect(() => {
    setLatLon(addressLatLon)
  }, [addressLatLon])

  return (
    <FilterRow>
      <Styled.LabelFieldColumn width="35%">
        <Styled.FieldLabel>Location</Styled.FieldLabel>
        <Styled.LocationSearchField
          onKeyDown={e => e.key === 'Enter' && setAddress(e.target.value)}
          onBlur={e => setAddress(e.target.value)}
          onChange={e => setLocationInput(e.target.value)}
          value={locationInput}
          placeholder="Zip Code or City (please hit enter to see results)"
          name="location"
        />
      </Styled.LabelFieldColumn>

      <Styled.LabelFieldColumn width="19%">
        <Styled.FieldLabel>Radius</Styled.FieldLabel>
        <Select
          options={RADIUS_OPTIONS}
          value={radiusValue}
          defaultValue={DEFAULT_RADIUS}
          styles={DROPDOWN_STYLES}
          height="42px"
          fontSize="1.3rem"
          onChange={e => setRadiusValue({ value: e.value, label: e.label })}
        />
      </Styled.LabelFieldColumn>

      <Styled.LabelFieldColumn width="100%">
        <Styled.FieldLabel>Search</Styled.FieldLabel>
        <SearchField
          placeholder="Search (please type a provider or practice name)"
          onChange={e => setSearchInput(e.target.value)}
          value={searchInput}
          name="search"
        />
      </Styled.LabelFieldColumn>
    </FilterRow>
  )
}

export default HcpdFilterRow
