import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { Link } from 'gatsby'

// TODO: There are common text patterns between this and
// the detail page that should be combined

export const HcpdListItem = styled.div`
  grid-column: 2 / 3;
`

export const CategoryColumn = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;

  svg {
    margin-bottom: 13px;
  }

  img {
    width: 150px;
    height: auto;
  }

  @media ${DEVICE.tabletL} {
    font-size: 1.4rem;
    line-height: 1.7;
  }

  :first-of-type {
    @media ${DEVICE.tabletL} {
      /* margin-right: auto; */
    }
  }
`

export const PracticeInfoColumn = styled(CategoryColumn)`
  @media ${DEVICE.tabletL} {
    grid-column: 1 / 5;
  }
`

export const PracticeDetailContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr) repeat(4, 1.25fr);
    column-gap: 20px;
  }
`

export const ColumnHeader = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.33;
  color: var(--highlight-blue);

  @media ${DEVICE.tabletL} {
    font-size: 1.4rem;
    line-height: 1.7;
  }
`

export const ProviderLink = styled(Link)`
  text-decoration-line: none;
  color: var(--content-background-dark-blue);

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 1.375;

    @media ${DEVICE.tabletL} {
      font-size: 2.8rem;
    }
  }

  :hover {
    text-decoration-line: underline;
    color: var(--highlight-blue);
  }
`

export const ItemDivider = styled.hr`
  border: 1px solid var(--content-background-dark-blue);
  margin: 30px 0;
`

// TODO: copied and pasted from HcpdDetailPage, these should be merged
export const PracticeContactDetailBlock = styled.div`
  margin: 10px 0;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.375;
    max-width: 240px;
    padding-bottom: 8px;
    color: var(--content-background-dark-blue);
  }

  p,
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.5;
    text-decoration-line: none;
    color: var(--content-background-dark-blue);
  }

  a {
    :hover {
      text-decoration-line: underline;
      color: var(--highlight-blue);

      p {
        color: var(--highlight-blue);
      }
    }
  }

  @media ${DEVICE.tabletL} {
    :first-of-type {
      margin-top: 6px;
    }

    :last-of-type {
      margin-top: 20px;
    }
  }
`

export const CategoryList = styled.ul`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.375;
  color: var(--content-background-dark-blue);
  padding-top: 4px;

  li {
    margin: 10px 0;

    :first-of-type {
      margin-top: 6px;
    }
  }

  svg {
    margin-bottom: 13px;
  }

  @media ${DEVICE.tabletL} {
    font-size: 1.6rem;
  }
`

/*
  @media ${DEVICE.tabletL} {

  }
*/
