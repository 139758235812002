import { useState, useEffect } from 'react'

const toRadian = angle => (Math.PI / 180) * angle
const distance = (a, b) => (Math.PI / 180) * (a - b)
const RADIUS_OF_EARTH_IN_KM = 6371
const GOOGLE_GEO_URL_PREFIX =
  'https://maps.googleapis.com/maps/api/geocode/json'

export const haversineDistance = ([lat1, lon1], [lat2, lon2]) => {
  const dLat = distance(lat2, lat1)
  const dLon = distance(lon2, lon1)

  lat1 = toRadian(lat1)
  lat2 = toRadian(lat2)

  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.asin(Math.sqrt(a))

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c
  finalDistance /= 1.60934
  return finalDistance
}

export const useLatLonFromAddress = address => {
  const [latLon, setLatLon] = useState({})
  // const [errors, setErrors] = useState(null)

  async function fetchData(query) {
    if (!query) {
      return null
    }
    const res = await fetch(query)
    // console.log(res.json())
    res
      .json()
      .then(r =>
        setLatLon(r.results?.slice(0, 1)?.pop()?.geometry?.location ?? {})
      )
    // .catch(e => setErrors(e))
  }

  useEffect(() => {
    if (address === null) {
      return
    }
    if (address !== '') {
      const query = `${GOOGLE_GEO_URL_PREFIX}?address=${address}&key=${process.env.GATSBY_GOOGLE_MAPS_KEY}`
      fetchData(query)
    } else {
      setLatLon({})
    }
  }, [address])

  return latLon
}
