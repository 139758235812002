import React from 'react'
import { PrismicRichText } from '@prismicio/react'

import * as Styled from './styles/HcpdPageHero'
import { RegionContainer } from '../styles/Utility.styles'

const HcpdPageHero = ({
  title,
  body,
  children = null,
  addMarginBottom = null,
}) => (
  <RegionContainer>
    <Styled.HeroContainer addMarginBottom={addMarginBottom}>
      <Styled.HeroTitle>{title}</Styled.HeroTitle>
      <Styled.HeroBody as="div">
        <PrismicRichText field={body.richText} />
      </Styled.HeroBody>
      {children}
    </Styled.HeroContainer>
  </RegionContainer>
)

export default HcpdPageHero
