import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import CtaLink from '../../components/ContentBlocks/CtaLink'
import HcpdLandingFilterableList from '../../components/HealthCareProviderDirectory/HcpdLandingFilterableList'
import { BottomLineGoogleAd } from '../../components/styles/GoogleAdsCommon.styles'

export const query = graphql`
  {
    prismicHcpdLandingPage {
      data {
        body_text {
          richText
        }
        facebook_image {
          url
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        title {
          text
          richText
        }
        twitter_image {
          url
        }
        cta_text
        no_matching_providers_text {
          richText
        }
        google_ad_page_bottom {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        cta_link {
          url
          uid
          type
          link_type
          id
        }
      }
    }
  }
`

const HcpdLandingPage = ({ data }) => {
  const doc = data.prismicHcpdLandingPage.data
  if (!doc) {
    return null
  }
  const adBlockId = doc.google_ad_page_bottom?.document.id
  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : 'Health Care Provider Directory'
  const seo_embed_code = doc.seo_embed_code?.text ?? null

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <HcpdPageHero
          title={doc.title?.text ?? 'Health Care Provider Directory'}
          body={doc.body_text}
        >
          <CtaLink text={doc.cta_text} link={doc.cta_link} />
        </HcpdPageHero>
        <HcpdLandingFilterableList
          noResultsMessage={doc.no_matching_providers_text?.richText ?? null}
        />
        {adBlockId && <BottomLineGoogleAd id={adBlockId} />}
      </Layout>
    </>
  )
}

export default HcpdLandingPage
