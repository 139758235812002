import styled from 'styled-components'

export const ClearButtonContainer = styled.button`
  grid-column: 2 / 3;
  background-color: unset;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  /* todo Addd svg rollover classes (a la CtaLink.styles.js) */
`

export const TextLink = styled.p`
  font-family: 'Open Sans', sans-serif;
  user-select: none;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  color: var(--content-background-dark-blue);
  padding-right: 12px;
`
