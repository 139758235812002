import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { HeroH1, HeroP } from '../../styles/Utility.styles'

export const HeroContainer = styled.section`
  grid-column: 2 / 3;
  margin-bottom: ${({ addMarginBottom }) =>
    addMarginBottom ? addMarginBottom.mobile : 'unset'};

  @media ${DEVICE.tabletL} {
    margin-bottom: ${({ addMarginBottom }) =>
      addMarginBottom ? addMarginBottom.desktop : 'unset'};
  }
`

export const HeroTitle = styled(HeroH1)`
  padding: 40px 0 14px 0;

  @media ${DEVICE.tabletL} {
    padding: 50px 0 20px 0;
  }
`

export const HeroBody = styled(HeroP)`
  h2 {
    padding: 20px 0 4px 0;
    line-height: 1.36;
    font-weight: 300;

    @media ${DEVICE.tabletL} {
      padding: 40px 0 4px 0;
    }
  }
`
