import React from 'react'

import * as Styled from './styles/ClearAllFiltersButton.styles'
import { ClearFiltersIcon } from '../Icons'

const ClearAllFiltersButton = ({ clearAction }) => {
  const clearFilters = e => {
    e.preventDefault()
    clearAction()
  }

  return (
    <Styled.ClearButtonContainer onClick={e => clearFilters(e)}>
      <Styled.TextLink>Clear All Filters</Styled.TextLink>
      <ClearFiltersIcon />
    </Styled.ClearButtonContainer>
  )
}

export default ClearAllFiltersButton
