import React, { useState, useEffect } from 'react'

import { RegionContainer } from '../styles/Utility.styles'
import * as Styled from './styles/AdditionalFiltersBlock.styles'
import AdditionalFiltersForm from './AdditionalFiltersForm'
import ClearAllFiltersButton from './ClearAllFiltersButton'

const AdditionalFiltersBlock = ({
  additionalFilters,
  setAdditionalFilters,
  setLatLon,
  setSearchInput,
  setLocationInput,
}) => {
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)
  const [toggleButtonMessage, setToggleButtonMessage] =
    useState('View All Filters')

  useEffect(() => {
    let buttonText = showAdditionalFilters
      ? 'Close All Filters'
      : 'View All Filters'
    if (additionalFilters.length > 0) {
      buttonText +=
        ' (You must clear all filters before beginning a new search)'
    }
    setToggleButtonMessage(buttonText)
  }, [additionalFilters, showAdditionalFilters])

  return (
    <RegionContainer>
      {showAdditionalFilters && (
        <AdditionalFiltersForm
          additionalFilters={additionalFilters}
          setAdditionalFilters={setAdditionalFilters}
        />
      )}
      <ClearAllFiltersButton
        clearAction={() => {
          setAdditionalFilters([])
          setLatLon({})
          setSearchInput('')
          setLocationInput('')
        }}
      />
      <Styled.FilterToggleButton
        onClick={e => {
          e.preventDefault()
          setShowAdditionalFilters(!showAdditionalFilters)
        }}
      >
        {toggleButtonMessage}
      </Styled.FilterToggleButton>
    </RegionContainer>
  )
}

export default AdditionalFiltersBlock
