import React from 'react'

import FILTERS from '../../data/hcpd-filter-categories.json'

import * as Styled from './styles/AdditionalFiltersForm.styles'

const AdditionalFiltersForm = ({ additionalFilters, setAdditionalFilters }) => {
  const handleCheck = (checked, category, option) => {
    // console.log(`${category} ${option} is ${checked}`)
    if (checked) {
      setAdditionalFilters([...additionalFilters, option])
    } else {
      setAdditionalFilters(additionalFilters.filter(e => e !== option))
    }
  }

  return (
    <Styled.FormContainer>
      <Styled.FilterForm>
        {FILTERS.map(category => (
          <Styled.FilterCategoryColumn key={category.category}>
            <Styled.FilterGroupHeading>
              {category.category}
            </Styled.FilterGroupHeading>
            {category.options.map(option => (
              <Styled.CheckboxLabelPair key={option}>
                <Styled.Checkbox
                  id={option}
                  checked={additionalFilters.includes(option)}
                  onChange={e =>
                    handleCheck(e.target.checked, category.category, option)
                  }
                />
                <label htmlFor={option}>{option}</label>
              </Styled.CheckboxLabelPair>
            ))}
          </Styled.FilterCategoryColumn>
        ))}
      </Styled.FilterForm>
    </Styled.FormContainer>
  )
}

export default AdditionalFiltersForm
